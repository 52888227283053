<template>
  <b-card :title="title">
    <final-payments-table :items="items" :loading="loading" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import FinalPaymentsTable from "@/views/bookings/final-payments/components/FinalPaymentsTable.vue";

export default {
  components: {
    BCard,
    FinalPaymentsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
  },
};
</script>
