<template>
  <final-payments-list
    title="Pagaments finals pendents de cobrar"
    :items="parsedPendingFinalPayments"
  />
</template>

<script>
import FinalPaymentsList from "@/views/bookings/final-payments/components/FinalPaymentsList.vue";
import { notifyError } from "@/utils/methods";
import {
  formatCurrency,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    FinalPaymentsList,
  },
  computed: {
    pendingFinalPayments() {
      return this.$store.getters["bookings/pendingFinalPayments"];
    },
    parsedPendingFinalPayments() {
      if (!this.pendingFinalPayments.length) return [];
      return this.pendingFinalPayments.map((booking) => {
        return {
          uuid: booking.uuid || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          fictitious: booking.fictitious || false,
          client: booking.client?.fullName || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          checkinOrder: formatDateStringToDatabaseDate(booking.checkin) || null,
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) || null,
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          finalPaymentDueDateOrder:
            formatDateStringToDatabaseDate(booking.finalPaymentDueDate) || null,
          finalPaymentDueDate:
            formatDateStringToDate(booking.finalPaymentDueDate) ||
            this.$t("No definit"),
          amount: this.getPendingAmount(booking) || this.$t("No definit"),
        };
      });
    },
  },
  created() {
    this.fetchPendingFinalPayments();
  },
  methods: {
    fetchPendingFinalPayments() {
      this.$store
        .dispatch("bookings/fetchBookingsWithPendingFinalPayment")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    getPendingAmount(booking) {
      if (!booking) return null;

      const bookingPayments = booking.clientPayments.filter(
        (clientPayment) =>
          clientPayment.scope === "BOOKING" &&
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          )
      );

      let paidAmount = 0;
      if (bookingPayments.length) {
        paidAmount = bookingPayments.reduce(
          (acc, clientPayment) => acc + clientPayment.pvpAmount,
          0
        );
      }

      const pending =
        booking.ratesPrice - (booking.discountPrice || 0) - paidAmount;

      return pending ? formatCurrency(pending) : null;
    },
  },
};
</script>
