<template>
  <div class="security-deposits-table">
    <!-- FILTER -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="12" md="auto" class="mb-1 mb-md-0">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
            />
          </template>

          <!-- Column: checkinOrder -->
          <template #cell(checkinOrder)="data">
            {{ data.item.checkin }}
          </template>

          <!-- Column: finalPaymentDueDateOrder -->
          <template #cell(finalPaymentDueDateOrder)="data">
            <feather-icon
              v-if="isExpiredPaymentDueDate(data.item.finalPaymentDueDateOrder)"
              icon="AlertTriangleIcon"
              class="text-danger font-weight-bold"
            />
            <span
              :class="{
                'text-danger': isExpiredPaymentDueDate(
                  data.item.finalPaymentDueDateOrder
                ),
              }"
            >
              {{ data.item.finalPaymentDueDate }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="items.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BSpinner,
    BBookingLocalizator,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "finalPaymentDueDateOrder",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "localizator", label: this.$t("Nº reserva") },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "client", label: this.$t("Client") },
        { key: "checkinOrder", label: this.$t("Entrada"), sortable: true },
        {
          key: "finalPaymentDueDateOrder",
          label: this.$t("Data límit"),
          sortable: true,
        },
        { key: "amount", label: this.$t("Import"), sortable: true },
      ],
      selectedItem: null,
    };
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    isExpiredPaymentDueDate(date) {
      if (!date) return false;
      const today = formatDateObjectToDatabaseDate(new Date());
      return today >= date;
    },
  },
};
</script>

<style lang="scss">
.security-deposits-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
